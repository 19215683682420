import React from "react";
import "katex/dist/katex.min.css";
import BaseLayout from "./BaseLayout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PageTitle from "./PageTitle";
import { getMetaTags } from "../Utils";

const StaticPage = ({ data: { mdx, site } }) => {
  const metaTags = getMetaTags({
    title: `${mdx.frontmatter.title} | ${site.siteMetadata.title}`,
    siteUrl: site.siteMetadata.siteUrl,
    slug: mdx.fields.slug,
    summary: mdx.frontmatter.summary,
  });
  return (
    <BaseLayout>
      {metaTags}
      <PageTitle>{mdx.frontmatter.title}</PageTitle>
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </BaseLayout>
  );
};
export const pageQuery = graphql`
  query StaticPage($id: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default StaticPage;
